import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "hooks";

import { GlobalIState } from "types/RootState";
import {
  formatPrice,
  getDefaultBackground,
  getDefaultColour,
} from "utils/utils";
import VinfinityWhiteLogo from "assets/images/VinfinityWhiteLogo.png";
import ButtonComponent from "components/Button/Button.component";

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import styles from "./NecktieLanding.module.scss";
import {
  getMagnumWinesBegin,
  getNeckTiesBegin,
  getWinesByCountryBegin,
  populateCartItems,
} from "pages/Purchasing/actions";
import NecktieFormatText from "components/NecktieFormatText/NecktieFormatText.component";
import {
  MinusRoundedIcon,
  RatingIcon,
  RoundedPlusIcon,
  ShoppingCartAddIcon,
} from "assets/icons/icons";
import StorageService from "services/storage.service";
import BottleShadow from "assets/images/bottle-shadow.png";
import useCheckMobileScreen from "components/hooks/UseCheckMobileScreen";
import { Badge } from "@mui/material";
import { openModal } from "App/actions";
import ChangeLanguageModal from "components/ChangeLanguage/ChangeLanguage.component";
import { modalTypes } from "types/reducers/modal.reducer.type";

const NecktieLanding: React.FC = () => {
  const {
    action: { isLoading },
    neckties,
    winesByCountry,
    magnumWines,
  } = useAppSelector((state: GlobalIState) => state.PurchasingReducer);
  const necktiesSliderRef = useRef<Slider | null>(null);
  const magnumSliderRef = useRef<Slider | null>(null);
  const { defaultColour, defaultBackground } = useAppSelector(
    (state: GlobalIState) => state.ProfilePageReducer
  );
  const { t: translations } = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const isMobile = useCheckMobileScreen();
  const getRandomNecktie = (arr) => arr[Math.floor(Math.random() * arr.length)];
  const [selectedItems, setSelectedItems] = useState([]);
  const necktieItemsCookie = () => StorageService.getDataByKey("necktieItems");
  const [selectedTab, setSelectedTab] = useState("ITALY");
  const [selectedProducts, setSelectedProducts] = useState([]);

  const getLanguage = () => {
    return localStorage.getItem("lang");
  };

  const handleSelectProduct = (item) => {
    const product = { ...item, quantity: 1 };
    setSelectedProducts([...selectedProducts, product]);
  };

  const handleCheckout = () => {
    StorageService.setKeyValue("orderType", "necktie");
    StorageService.setKeyValue("necktieItems", selectedItems);
    localStorage.setItem("cartItems", JSON.stringify([...selectedProducts]));
    dispatch(populateCartItems([...selectedProducts]));
    navigate("/cart");
  };

  const necktieSliderSettings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    arrows: false,
  };

  const magnumSliderSettings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
  };

  const getLang = () => {
    return localStorage.getItem("lang");
  };

  const handleLanguageChangeModal = () => {
    dispatch(
      openModal({
        content: <ChangeLanguageModal />,
        type: modalTypes.mdNoBg,
      })
    );
  };

  const toggleSelect = (id: number, price: number, img_url: string) => {
    setSelectedItems((prevSelectedItems) => {
      const itemIndex = prevSelectedItems.findIndex(
        (item) => item.necktie_id === id
      );

      let updatedSelectedItems;
      if (itemIndex !== -1) {
        updatedSelectedItems = prevSelectedItems.filter(
          (item) => item.necktie_id !== id
        );
      } else {
        updatedSelectedItems = [
          ...prevSelectedItems,
          {
            order_id: 1,
            product_id: null,
            necktie_id: id,
            quantity: 1,
            unit_price: price.toFixed(2),
            total_price: price.toFixed(2),
            img_url: img_url,
          },
        ];
      }

      return updatedSelectedItems;
    });
  };

  const incrementQuantity = (id: number) => {
    setSelectedItems((prevSelectedItems) =>
      prevSelectedItems.map((item) =>
        item.necktie_id === id
          ? {
              ...item,
              quantity: item.quantity + 1,
              total_price: (
                (item.quantity + 1) *
                parseFloat(item.unit_price)
              ).toFixed(2),
            }
          : item
      )
    );
  };

  const decrementQuantity = (id: number) => {
    setSelectedItems((prevSelectedItems) =>
      prevSelectedItems
        .map((item) =>
          item.necktie_id === id
            ? {
                ...item,
                quantity: item.quantity - 1,
                total_price: (
                  (item.quantity - 1) *
                  parseFloat(item.unit_price)
                ).toFixed(2),
              }
            : item
        )
        .filter((item) => item.quantity > 0)
    );
  };

  const productIncrementQuantity = (id: number) => {
    setSelectedProducts(
      selectedProducts.map((item) =>
        item.id === id ? { ...item, quantity: item.quantity + 1 } : item
      )
    );
  };

  const productDecrementQuantity = (id: number) => {
    setSelectedProducts(
      selectedProducts
        .map((item) =>
          item.id === id ? { ...item, quantity: item.quantity - 1 } : item
        )
        .filter((item) => item.quantity > 0)
    );
  };

  function getTotalQuantity(): number {
    const sumQuantities = (array: any[] | undefined) =>
      array?.reduce((sum, item) => sum + item.quantity, 0) || 0;

    const totalItems = sumQuantities(selectedItems);
    const totalProducts = sumQuantities(selectedProducts);

    return totalItems + totalProducts;
  }

  useEffect(() => {
    dispatch(getNeckTiesBegin());
    dispatch(getMagnumWinesBegin(getLanguage()));
  }, []);

  useEffect(() => {
    dispatch(
      getWinesByCountryBegin({ country: selectedTab, lang: getLanguage() })
    );
  }, [selectedTab]);

  return (
    <div
      className={styles.container}
      style={
        {
          "--color": getDefaultColour(defaultColour),
        } as React.CSSProperties
      }
    >
      <div className={styles.top}>
        <div className={styles.box}>
          <div>
            <img src={VinfinityWhiteLogo} alt="Vinfinity Logo" />
          </div>

          <div className={styles.rightButton}>
            <div
              className={styles.changeLanguageBtn}
              onClick={() => handleLanguageChangeModal()}
            >
              <span>{getLang()?.toUpperCase()}</span>
            </div>
            <Badge
              className={styles.badge}
              badgeContent={getTotalQuantity()}
              color="info"
              style={{ backgroundColor: "#FF6333" }}
            />
            <div
              style={
                getTotalQuantity() === 0
                  ? { pointerEvents: "none", opacity: 0.8 }
                  : {}
              }
            >
              {isMobile ? (
                <div
                  className={styles.checkoutIcon}
                  onClick={() => handleCheckout()}
                >
                  <ShoppingCartAddIcon color="#000" />
                </div>
              ) : (
                <ButtonComponent
                  label={translations("label.checkout")}
                  class={"btn btn-white-bordered"}
                  handleClick={handleCheckout}
                />
              )}
            </div>
          </div>
        </div>
      </div>
      <div
        style={{
          backgroundImage: `url(${getDefaultBackground("2")})`,
        }}
        className={styles.mainBanner}
      >
        <div className={styles.textContainer}>
          <div className={styles.orangeLabel}>
            <span>{translations("label.step", { count: 1 })}</span>
          </div>
          <div className={styles.right}>
            <h1>{translations("label.step_1.title")}</h1>
            <span>{translations("label.step_1.subtitle")}</span>
          </div>
        </div>
        <div className={styles.necktieSelection}>
          {isMobile ? (
            <div className={styles.sliderContainer}>
              {neckties?.map((item, index) => (
                <div
                  key={index}
                  className={`${styles.necktieItem} ${
                    selectedItems.some(
                      (selected) => selected.necktie_id === item.id
                    )
                      ? styles.selected
                      : styles.unselectedItem
                  }`}
                  onClick={() => {
                    if (
                      selectedItems.some(
                        (selected) => selected.necktie_id === item.id
                      )
                    ) {
                      return;
                    } else toggleSelect(item.id, item.price, item?.image_url);
                  }}
                >
                  {selectedItems.some(
                    (selected) => selected.necktie_id === item.id
                  ) ? (
                    <div
                      className={styles.deselect}
                      onClick={() =>
                        toggleSelect(item.id, item.price, item?.image_url)
                      }
                    >
                      -
                    </div>
                  ) : null}
                  {selectedItems.some(
                    (selected) => selected.necktie_id === item.id
                  ) ? (
                    <div
                      style={{
                        cursor: "pointer",
                      }}
                      className={styles.necktieTop}
                      onClick={() =>
                        toggleSelect(item.id, item.price, item?.image_url)
                      }
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 332.09 75.6"
                      >
                        <g>
                          <path
                            style={{
                              fill: "#eaeef5",
                            }}
                            d="M287.84,1H44.15c-11.97,0-22.54,7.78-26.1,19.21L.9,75.3h330.18l-17.14-55.09c-3.56-11.43-14.13-19.21-26.1-19.21ZM165.99,56.9c-31.66,0-57.33-8.39-57.33-18.75s25.67-18.75,57.33-18.75,57.33,8.4,57.33,18.75-25.67,18.75-57.33,18.75Z"
                          />
                          <path
                            style={{
                              fill: "none",
                              stroke: "#600f83",
                              strokeMiterlimit: 10,
                              strokeWidth: 4,
                            }}
                            d="M331.13,75.3l-17.14-55.09c-3.56-11.43-14.13-19.21-26.1-19.21H44.21c-11.97,0-22.54,7.78-26.1,19.21L.95,75.3"
                          />
                        </g>
                      </svg>
                    </div>
                  ) : (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 330.18 74.3"
                      className={styles.necktieTop}
                    >
                      <g>
                        <path
                          style={{
                            fill: "#eaeef5",
                          }}
                          d="M286.94,0H43.25c-11.97,0-22.54,7.78-26.1,19.21L0,74.3h330.18l-17.14-55.09c-3.56-11.43-14.13-19.21-26.1-19.21ZM165.09,55.9c-31.66,0-57.33-8.39-57.33-18.75s25.67-18.75,57.33-18.75,57.33,8.4,57.33,18.75-25.67,18.75-57.33,18.75Z"
                        />
                      </g>
                    </svg>
                  )}
                  <div className={styles.content}>
                    <NecktieFormatText text={item?.image_text} />
                  </div>
                  <div className={styles.bottomContent}>
                    {!selectedItems.some(
                      (selected) => selected.necktie_id === item.id
                    ) ? (
                      <span
                        className={`${styles.bottomContent} ${
                          selectedItems.some(
                            (selected) => selected.necktie_id === item.id
                          )
                            ? styles.selectedItem
                            : styles.unselectedItem
                        }`}
                      >
                        {translations("label.select")}
                      </span>
                    ) : null}

                    {selectedItems.some(
                      (selected) => selected.necktie_id === item.id
                    ) ? (
                      <div className={styles.necktieQuantity}>
                        <div
                          onClick={(e) => {
                            e.stopPropagation();
                            decrementQuantity(item.id);
                          }}
                        >
                          <MinusRoundedIcon color={"#000"} />
                        </div>
                        <span>
                          {
                            selectedItems.find(
                              (selected) => selected.necktie_id === item.id
                            )?.quantity
                          }
                        </span>
                        <div
                          onClick={(e) => {
                            e.stopPropagation();
                            incrementQuantity(item.id);
                          }}
                        >
                          <RoundedPlusIcon color={"#000"} />
                        </div>
                      </div>
                    ) : null}
                  </div>
                </div>
              ))}
            </div>
          ) : (
            <div className={styles.sliderContainer}>
              <div
                className={styles.prevArrow}
                onClick={() => necktiesSliderRef.current?.slickPrev()}
              >
                {"<"}
              </div>

              <Slider ref={necktiesSliderRef} {...necktieSliderSettings}>
                {neckties?.map((item, index) => (
                  <div
                    key={index}
                    className={`${styles.necktieItem} ${
                      selectedItems.some(
                        (selected) => selected.necktie_id === item.id
                      )
                        ? styles.selected
                        : styles.unselectedItem
                    }`}
                    onClick={() => {
                      if (
                        selectedItems.some(
                          (selected) => selected.necktie_id === item.id
                        )
                      ) {
                        return;
                      } else toggleSelect(item.id, item.price, item?.image_url);
                    }}
                  >
                    {selectedItems.some(
                      (selected) => selected.necktie_id === item.id
                    ) ? (
                      <div
                        className={styles.deselect}
                        onClick={() =>
                          toggleSelect(item.id, item.price, item?.image_url)
                        }
                      >
                        -
                      </div>
                    ) : null}

                    {selectedItems.some(
                      (selected) => selected.necktie_id === item.id
                    ) ? (
                      <div
                        style={{
                          cursor: "pointer",
                        }}
                        className={styles.necktieTop}
                        onClick={() =>
                          toggleSelect(item.id, item.price, item?.image_url)
                        }
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 332.09 75.6"
                        >
                          <g>
                            <path
                              style={{
                                fill: "#eaeef5",
                              }}
                              d="M287.84,1H44.15c-11.97,0-22.54,7.78-26.1,19.21L.9,75.3h330.18l-17.14-55.09c-3.56-11.43-14.13-19.21-26.1-19.21ZM165.99,56.9c-31.66,0-57.33-8.39-57.33-18.75s25.67-18.75,57.33-18.75,57.33,8.4,57.33,18.75-25.67,18.75-57.33,18.75Z"
                            />
                            <path
                              style={{
                                fill: "none",
                                stroke: "#600f83",
                                strokeMiterlimit: 10,
                                strokeWidth: 4,
                              }}
                              d="M331.13,75.3l-17.14-55.09c-3.56-11.43-14.13-19.21-26.1-19.21H44.21c-11.97,0-22.54,7.78-26.1,19.21L.95,75.3"
                            />
                          </g>
                        </svg>
                      </div>
                    ) : (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 330.18 74.3"
                        className={styles.necktieTop}
                      >
                        <g>
                          <path
                            style={{
                              fill: "#eaeef5",
                            }}
                            d="M286.94,0H43.25c-11.97,0-22.54,7.78-26.1,19.21L0,74.3h330.18l-17.14-55.09c-3.56-11.43-14.13-19.21-26.1-19.21ZM165.09,55.9c-31.66,0-57.33-8.39-57.33-18.75s25.67-18.75,57.33-18.75,57.33,8.4,57.33,18.75-25.67,18.75-57.33,18.75Z"
                          />
                        </g>
                      </svg>
                    )}
                    <div className={styles.content}>
                      <NecktieFormatText text={item?.image_text} />
                    </div>
                    <div className={styles.bottomContent}>
                      {!selectedItems.some(
                        (selected) => selected.necktie_id === item.id
                      ) ? (
                        <span
                          className={`${styles.bottomContent} ${
                            selectedItems.some(
                              (selected) => selected.necktie_id === item.id
                            )
                              ? styles.selectedItem
                              : styles.unselectedItem
                          }`}
                        >
                          {translations("label.select")}
                        </span>
                      ) : null}

                      {selectedItems.some(
                        (selected) => selected.necktie_id === item.id
                      ) ? (
                        <div className={styles.necktieQuantity}>
                          <div
                            onClick={(e) => {
                              e.stopPropagation();
                              decrementQuantity(item.id);
                            }}
                          >
                            <MinusRoundedIcon color={"#000"} />
                          </div>
                          <span>
                            {
                              selectedItems.find(
                                (selected) => selected.necktie_id === item.id
                              )?.quantity
                            }
                          </span>
                          <div
                            onClick={(e) => {
                              e.stopPropagation();
                              incrementQuantity(item.id);
                            }}
                          >
                            <RoundedPlusIcon color={"#000"} />
                          </div>
                        </div>
                      ) : null}
                    </div>
                  </div>
                ))}
              </Slider>

              <div
                className={styles.nextArrow}
                onClick={() => necktiesSliderRef.current?.slickNext()}
              >
                {">"}
              </div>
            </div>
          )}
          {isMobile ? (
            <div className={styles.bottomLabel}>
              <span>{translations("label.scroll_to_explore_all_options")}</span>
            </div>
          ) : null}
          <div className={styles.preview}>
            <div className={styles.necktiePreview}>
              <img
                src={
                  selectedItems?.length === 0
                    ? getRandomNecktie(neckties)?.image_url
                    : selectedItems?.[selectedItems.length - 1]?.img_url
                }
              />
              <span>{translations("label.preview")}</span>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.wineSelection}>
        <div className={styles.textContainer}>
          <div className={styles.orangeLabel}>
            <span>{translations("label.step", { count: 2 })}</span>
          </div>
          <div className={styles.right}>
            <h1>{translations("label.step_2.title")}</h1>
            <span>{translations("label.step_2.subtitle")}</span>
          </div>
        </div>
        {isMobile ? (
          <select
            name={"bottle_size"}
            value={selectedTab}
            className={styles.selectInput}
            onChange={(e) => setSelectedTab(e.target.value)}
          >
            <option value="0" disabled>
              {/* {translations("label.bottle_size")} */}
            </option>

            <option value="PORTUGAL">PORTUGAL</option>
            <option value="FRANCE">FRANCE</option>
            <option value="SPAIN">SPAIN</option>
            <option value="ITALY">ITALY</option>
          </select>
        ) : (
          <div className={styles.tabs}>
            <div
              style={
                selectedTab === "PORTUGAL"
                  ? { backgroundColor: "#000", color: "#fff" }
                  : {}
              }
              onClick={() => setSelectedTab("PORTUGAL")}
            >
              PORTUGAL
            </div>
            <div
              style={
                selectedTab === "FRANCE"
                  ? { backgroundColor: "#000", color: "#fff" }
                  : {}
              }
              onClick={() => setSelectedTab("FRANCE")}
            >
              FRANCE
            </div>
            <div
              style={
                selectedTab === "SPAIN"
                  ? { backgroundColor: "#000", color: "#fff" }
                  : {}
              }
              onClick={() => setSelectedTab("SPAIN")}
            >
              SPAIN
            </div>
            <div
              style={
                selectedTab === "ITALY"
                  ? { backgroundColor: "#000", color: "#fff" }
                  : {}
              }
              onClick={() => setSelectedTab("ITALY")}
            >
              ITALY
            </div>
          </div>
        )}
        <div className={styles.products}>
          {winesByCountry?.map((item, index) => {
            return (
              <div
                key={index}
                style={{
                  border: selectedProducts.some(
                    (product) => product.id === item.id
                  )
                    ? `1px solid ${getDefaultColour(defaultColour)}`
                    : "1px solid #d9d9d9",
                }}
                className={styles.product}
              >
                <div className={styles.infoParts}>
                  {item?.is_special ? (
                    <div className={styles.specialInfo}>
                      <div className={styles.productRating}>
                        <RatingIcon />
                        {item?.scores?.length >= 1 ? (
                          <span>
                            {item?.scores[0]?.score} /{" "}
                            {item?.scores[0]?.max_score}
                          </span>
                        ) : (
                          <span>96 / 100</span>
                        )}
                      </div>
                      <div>
                        <div className={styles.name}>
                          <div>
                            <span>
                              {item?.wine_name?.length > 15
                                ? item?.wine_name?.substring(0, 15) + "..."
                                : item?.wine_name}
                            </span>
                          </div>
                          <br />
                          <div className={styles.vintageLabel}>
                            <span>{item?.vintage}</span>
                          </div>
                        </div>

                        <div className={styles.bottleSizeLabel}>
                          <span>{item?.bottle_size}</span>
                        </div>
                        <div className={styles.regionLabel}>
                          <span>{item?.region}</span>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div>
                      <div className={styles.productRating}>
                        <RatingIcon />
                        {item?.scores?.length >= 1 ? (
                          <span>
                            {item?.scores[0]?.score} /{" "}
                            {item?.scores[0]?.max_score}
                          </span>
                        ) : (
                          <span>96 / 100</span>
                        )}
                      </div>

                      <div>
                        <div className={styles.name}>
                          <div>
                            <span>
                              {item?.wine_name?.length > 15
                                ? item?.wine_name?.substring(0, 15) + "..."
                                : item?.wine_name}
                            </span>
                          </div>
                          <br />
                          <div className={styles.vintageLabel}>
                            <span>{item?.vintage}</span>
                          </div>
                        </div>

                        <div className={styles.bottleSizeLabel}>
                          <span>{item?.bottle_size}</span>
                        </div>
                        <div className={styles.regionLabel}>
                          <span>{item?.region}</span>
                        </div>
                      </div>
                    </div>
                  )}

                  <div className={styles.bottoms}>
                    <div className={styles.price}>
                      {item?.old_price ? (
                        <>
                          <span className={styles.discount}>
                            CHF
                            {formatPrice(item.old_price?.toString())}
                          </span>
                          <br />
                        </>
                      ) : null}
                      <span>CHF {item?.price}</span>
                    </div>

                    <div className={styles.productActions}>
                      {selectedProducts.some(
                        (product) => product.id === item.id
                      ) ? (
                        <div className={styles.quantityActions}>
                          <div
                            onClick={() => productDecrementQuantity(item.id)}
                          >
                            <MinusRoundedIcon color={"#000"} />
                          </div>
                          <span>
                            {
                              selectedProducts?.filter(
                                (a) => a.id === item.id
                              )[0]?.quantity
                            }
                          </span>
                          <div
                            onClick={() => productIncrementQuantity(item.id)}
                          >
                            <RoundedPlusIcon color={"#000"} />
                          </div>
                        </div>
                      ) : (
                        <div
                          className={styles.selectProductButton}
                          onClick={() => handleSelectProduct(item)}
                        >
                          <span>{translations("label.select")}</span>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div className={styles.imagePart}>
                  <img
                    className={styles.productImage}
                    src={item?.product_image}
                    alt={item?.producer}
                  />
                  <img
                    src={BottleShadow}
                    className={styles.bottleShadow}
                    alt="Shadow"
                  />
                </div>

                <div className={styles.productInfo}>
                  <div className={styles.boldText}>
                    <span>{item?.hover_text}</span>
                  </div>
                  <div>
                    <span>{item?.pairing}</span>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
      <div className={styles.magnumContent}>
        <div className={styles.textContainer}>
          <div className={styles.orangeLabel}>
            <span>{translations("label.optional")}</span>
          </div>
          <div className={styles.right}>
            <h1>{translations("label.or_go_magnum.title")}</h1>
            <span>{translations("label.or_go_magnum_subtitle")}</span>
          </div>
        </div>

        <div className={styles.productSlider}>
          <div
            className={styles.prevArrow}
            onClick={() => magnumSliderRef.current?.slickPrev()}
          >
            {"<"}
          </div>

          <Slider ref={magnumSliderRef} {...magnumSliderSettings}>
            {magnumWines?.map((item, index) => {
              return (
                <div
                  key={index}
                  style={{
                    border: selectedProducts.some(
                      (product) => product.id === item.id
                    )
                      ? `1px solid ${getDefaultColour(defaultColour)}`
                      : "1px solid #d9d9d9",
                  }}
                  className={styles.product}
                >
                  <div className={styles.infoParts}>
                    {item?.is_special ? (
                      <div className={styles.specialInfo}>
                        <div className={styles.productRating}>
                          <RatingIcon />
                          {item?.scores?.length >= 1 ? (
                            <span>
                              {item?.scores[0]?.score} /{" "}
                              {item?.scores[0]?.max_score}
                            </span>
                          ) : (
                            <span>96 / 100</span>
                          )}
                        </div>
                        <div className={styles.ratingsInfo}>
                          <div className={styles.name}>
                            <div>
                              <span>
                                {item?.wine_name?.length > 15
                                  ? item?.wine_name?.substring(0, 15) + "..."
                                  : item?.wine_name}
                              </span>
                            </div>
                            <br />
                            <div className={styles.vintageLabel}>
                              <span>{item?.vintage}</span>
                            </div>
                          </div>

                          <div className={styles.bottleSizeLabel}>
                            <span>{item?.bottle_size}</span>
                          </div>
                          <div className={styles.regionLabel}>
                            <span>{item?.region}</span>
                          </div>
                        </div>
                      </div>
                    ) : (
                      <>
                        <div className={styles.productRating}>
                          <RatingIcon />
                          {item?.scores?.length >= 1 ? (
                            <span>
                              {item?.scores[0]?.score} /{" "}
                              {item?.scores[0]?.max_score}
                            </span>
                          ) : (
                            <span>96 / 100</span>
                          )}
                        </div>

                        <div>
                          <div className={styles.name}>
                            <div>
                              <span>
                                {item?.wine_name?.length > 15
                                  ? item?.wine_name?.substring(0, 15) + "..."
                                  : item?.wine_name}
                              </span>
                            </div>
                            <br />
                            <div className={styles.vintageLabel}>
                              <span>{item?.vintage}</span>
                            </div>
                          </div>

                          <div className={styles.bottleSizeLabel}>
                            <span>{item?.bottle_size}</span>
                          </div>
                          <div className={styles.regionLabel}>
                            <span>{item?.region}</span>
                          </div>
                        </div>
                      </>
                    )}

                    <div className={styles.bottoms}>
                      <div className={styles.price}>
                        {item?.old_price ? (
                          <>
                            <span className={styles.discount}>
                              CHF
                              {formatPrice(item.old_price?.toString())}
                            </span>
                            <br />
                          </>
                        ) : null}
                        <span>CHF {item?.price}</span>
                      </div>

                      <div className={styles.productActions}>
                        {selectedProducts.some(
                          (product) => product.id === item.id
                        ) ? (
                          <div className={styles.quantityActions}>
                            <div
                              onClick={() => productDecrementQuantity(item.id)}
                            >
                              <MinusRoundedIcon color={"#000"} />
                            </div>
                            <span>
                              {
                                selectedProducts?.filter(
                                  (a) => a.id === item.id
                                )[0]?.quantity
                              }
                            </span>
                            <div
                              onClick={() => productIncrementQuantity(item.id)}
                            >
                              <RoundedPlusIcon color={"#000"} />
                            </div>
                          </div>
                        ) : (
                          <div
                            className={styles.selectProductButton}
                            onClick={() => handleSelectProduct(item)}
                          >
                            <span>{translations("label.select")}</span>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  {isMobile ? null : (
                    <div className={styles.middlePart}>
                      <div>
                        <span>{item?.note}</span>
                      </div>

                      <div className={styles.data}>
                        <div>
                          <div>
                            <span className={styles.title}>
                              {translations("label.country")}:
                            </span>
                            <span> {item?.productCountry}</span>
                          </div>
                          <div>
                            <span className={styles.title}>
                              {translations("label.region")}:
                            </span>
                            <span> {item?.region}</span>
                          </div>
                          <div>
                            <span className={styles.title}>
                              {translations("label.appellation")}:
                            </span>
                            <span> {item?.appellation}</span>
                          </div>
                          <div>
                            <span className={styles.title}>
                              {translations("label.best_before")}:
                            </span>
                            <span> {item?.best_before}</span>
                          </div>
                        </div>

                        <div>
                          <div>
                            <span className={styles.title}>
                              {translations("label.vintage")}:
                            </span>
                            <span> {item?.vintage}</span>
                          </div>
                          <div>
                            <span className={styles.title}>
                              {translations("label.type")}:
                            </span>
                            <span> {item?.productType}</span>
                          </div>
                          <div>
                            <span className={styles.title}>
                              {translations("label.alcohol")}:
                            </span>
                            <span> {item?.alcohol}</span>
                          </div>
                        </div>
                        <div>
                          <span className={styles.title}>
                            {translations("label.grape_varietal")}:
                          </span>
                          <span> {item?.productGrapes}</span>
                        </div>
                      </div>
                    </div>
                  )}
                  <div className={styles.imagePart}>
                    <img
                      className={styles.productImage}
                      src={item?.product_image}
                      alt={item?.producer}
                    />
                    {/* <img
                      src={BottleShadow}
                      className={styles.bottleShadow}
                      alt="Shadow"
                    /> */}
                  </div>
                </div>
              );
            })}
          </Slider>

          <div
            className={styles.nextArrow}
            onClick={() => magnumSliderRef.current?.slickNext()}
          >
            {">"}
          </div>
        </div>

        <div className={styles.buttons}>
          <ButtonComponent
            label={translations("label.explore_more_wines")}
            class={"btn btn-white-bordered"}
            handleClick={() => navigate("/purchasing")}
          />
          <ButtonComponent
            label={translations("label.go_to_checkout")}
            class={"btn btn-white-bordered"}
            style={
              getTotalQuantity() === 0
                ? { pointerEvents: "none", opacity: 0.8, color: "#fff" }
                : { color: "#fff" }
            }
            handleClick={handleCheckout}
          />
        </div>
      </div>
    </div>
  );
};

export default NecktieLanding;
